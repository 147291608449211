import { CancleIcon } from 'assets';
import { Images } from 'assets/Images';
import { Button } from 'components/Atoms/Button/Button'
import { Card } from 'components/Atoms/Card/Card';
import { Typography } from 'components/Atoms/Typography/Typography';
import { Modal } from 'components/Molecules/Modal';

const OpBnb = ({onClose , onUpdate, success, loading, handleApiReload}: {onClose:()=>void; onUpdate:()=>void; success:boolean; loading:boolean; handleApiReload:()=>void;}) => {
  return (
    <Modal blurImg>
    <Card pseudoElement="default" className=" !p-0">
        <button onClick={() => !loading && onClose()} className="cursor-pointer absolute top-2 right-2">
            <CancleIcon />
        </button>
        <div className="flex flex-col justify-center items-center text-center">
            <Typography
                isIcon={false}
                className=" text-text-secondary text-xxxxl leading-10 
                    mobile:text-xxl mobile:leading-6 mobile:tracking-tight  uppercase"
            >
                {success ? 'congratulations' : 'Mint Opbnb'}
            </Typography>    
            {success && <p
                        className="text-text-primary text-center mobile:text-small cursor-default mt-[10px]"
                    >
                        Minted Successfully
             </p>}    
            <div className="h-80 w-full mobile:h-[280px] ">
                <img
                    src={Images.OPBNB}
                    alt="astraNova"
                    className="m-auto max-w-[300px] min-w-[100px] w-full"
                />
            </div>
            <div className="flex items-center justify-center gap-3 mobile:flex-col mobile:gap-2 mobile:mt-3">
                <div className="group">
                    <Button
                        bgColor={true}
                        onClick={() => { 
                            if(success){ 
                                onClose()
                                handleApiReload()
                            }else{ 
                                onUpdate() 
                            }
                        }}
                        CLASSNAME={` text-text-primary group-hover:text-text-secondary  ${success ? 'px-3 mobile:px-5' : 'px-8'}`}
                        type="submit"
                        color="white"
                        disable={loading}
                        isBorderLabel={success ? "Back To Dashboard" : "Mint"}
                        isBorder={true}
                    />
                </div>
            </div>
        </div>
    </Card>
</Modal>
  )
}

export default OpBnb