import { Images } from "assets/Images";
import React, { useEffect, useState } from "react";

export const SuperNovaProgressTimer = ({
  startTime = Math.floor(Date.now()), // default to current time in milliseconds
  endTime,
  durationTime,
  durationType,
  displayType = "both",
  component,
  handleAction,
}: {
  startTime?: any; // Expecting timestamp in milliseconds
  endTime?: any; // Expecting timestamp in milliseconds
  durationTime?: any;
  durationType?: "Date" | "Hours" | "Min";
  displayType?: "progressBar" | "time" | "both";
  component?: "SuperNova";
  handleAction?: (val: boolean) => void;
}) => {
  startTime = new Date(startTime);

  // Calculate endTime based on durationTime and durationType if endTime is not provided
  // if (durationTime && !endTime) {
  //   endTime = new Date(startTime);
  //   if (durationType === "Date") {
  //     endTime.setUTCDate(startTime.getUTCDate() + durationTime);
  //   } else if (durationType === "Hours") {
  //     endTime.setUTCHours(startTime.getUTCHours() + durationTime);
  //   } else if (durationType === "Min") {
  //     endTime.setUTCMinutes(startTime.getUTCMinutes() + durationTime);
  //   }
  // } else if (endTime) {
  // }
  endTime = new Date(endTime);

  // Calculate total duration in milliseconds
  const totalDuration = endTime.getTime() - startTime.getTime();

  // State to hold progress percentage
  const [progress, setProgress] = useState(0);
  const [remainingTime, setRemainingTime] = useState(calculateRemainingTime());
  const [displayDays, setDisplayDays] = useState(calculateRemainingDays());

  // Function to calculate remaining time
  function calculateRemainingTime() {
    const now = new Date();
    const remaining = endTime.getTime() - now.getTime();
    return remaining > 0 ? remaining : 0;
  }

  // Function to calculate remaining days
  function calculateRemainingDays() {
    const now = new Date();
    const remaining = endTime.getTime() - now.getTime();
    return Math.floor(remaining / (1000 * 60 * 60 * 24));
  }

  // Function to update progress
  const updateProgress = () => {
    let now = new Date();
    // Ensure the progress bar doesn't go beyond the present time (end time)
    if (now >= endTime) {
      setProgress(100);
      setRemainingTime(0);
      setDisplayDays(0);
      // handleAction && handleAction(true);
      return;
    }

    let elapsedTime = now.getTime() - startTime.getTime();

    // Ensure elapsed time does not exceed total duration
    elapsedTime = Math.min(elapsedTime, totalDuration);

    const percentage = (elapsedTime / totalDuration) * 100;
    setProgress(percentage); // Set progress directly as percentage

    // Update remaining time
    const remaining = endTime.getTime() - now.getTime();
    setRemainingTime(remaining > 0 ? remaining : 0);

    // Update remaining days
    const days = Math.floor(remaining / (1000 * 60 * 60 * 24));
    setDisplayDays(days);
  };

  // Update progress and remaining time every second if less than a day, otherwise just set days
  useEffect(() => {
    if (displayDays <= 0) {
      updateProgress();
      const interval = setInterval(updateProgress, 1000);
      return () => clearInterval(interval);
    } else {
      updateProgress();
    }
  }, [displayDays]);

  // Format remaining time as hh:mm:ss
  const formatTime = (ms: number) => {
    const seconds = Math.floor((ms / 1000) % 60);
    const minutes = Math.floor((ms / (1000 * 60)) % 60);
    const hours = Math.floor((ms / (1000 * 60 * 60)) % 24);

    return `${hours.toString().padStart(2, "0")}h : ${minutes
      .toString()
      .padStart(2, "0")}m : ${seconds.toString().padStart(2, "0")}s`;
  };

  const handleRender = (val: any) => {
    switch (val) {
      case "SuperNova":
        return (
          <div className="relative text-text-primary">
            <img
              src={Images.PROGRESS_BAR}
              alt="progressbar"
              className="relative"
            />
            <div className="absolute bg-transparent h-full inset-0 w-[85%] mx-auto">
              {/* timer */}
              <div className="absolute top-[.20rem] left-0 w-full flex items-center justify-center text-base text-text-primary">
                {displayDays > 0 ? (
                  <span>{displayDays} days Left</span>
                ) : (
                  <span>{formatTime(remainingTime)} Left</span>
                )}
              </div>
              {/* progress bar */}
              <div 
                style={{
                    width: `${progress}%`                  
                  }} 
                  className="absolute h-1 bottom-[.30rem] left-0 drop-shadow-primary">
                  <img
                    src={Images.PROGRESS}
                    alt=""
                    className="h-full w-full object-fill"
                  />
                </div>
            </div>
          </div>
        );

      default:
        return (
          <div className="text-text-primary">
            {displayType === "progressBar" || displayType === "both" ? (
              <div
                className="progress-bar"
                style={{
                  width: `${progress}%`,
                  backgroundColor: "blue",
                  height: "20px",
                  position: "relative",
                }}
              >
                <div
                  className="progress-text"
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    color: "white",
                  }}
                >
                  {progress.toFixed(2)}%
                </div>
              </div>
            ) : null}
            {displayType === "time" || displayType === "both" ? (
              <div className="countdown">
                {displayDays > 0 ? (
                  <span>Remaining Days: {displayDays}d</span>
                ) : (
                  <span>Remaining Time: {formatTime(remainingTime)}</span>
                )}
              </div>
            ) : null}
          </div>
        );
    }
  };

  return handleRender(component);
};
