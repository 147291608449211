import { CancleIcon } from "assets";
import { Button } from "components/Atoms/Button/Button";
import { Card } from "components/Atoms/Card/Card";
import { Typography } from "components/Atoms/Typography/Typography";
import { Modal } from "components/Molecules/Modal";
import { SuperNovaCard } from "../Card";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAccount } from "wagmi";
import { Smallloader } from "components/Loader";
import { useUser } from "context/userContext";

export const UnStakeNft = ({
  onClose,
  onUnstakeNFT,
  isloading,
  unStakelist,
  setnftTokenIds,
  totalNoOfStakes,
  isStakeLoading,
  checkTime,
  setUserStakeList,
  userStakeList,
}: {
  onClose: () => void;
  onUnstakeNFT?: (userId: string, selectedNft: number[]) => any;
  isloading?: boolean;
  unStakelist?: any[];
  setnftTokenIds?: React.Dispatch<any>;
  totalNoOfStakes?: number;
  isStakeLoading?: boolean;
  checkTime?: () => boolean | null;
  setUserStakeList?: React.Dispatch<any>;
  userStakeList?: any[];
}) => {
  const [selectNft, setSelectNft] = useState<number[]>([]);
  const { user } = useUser();

  const handleunStakeNft = (data: any) => {
    let findToken = selectNft.find((tk) => tk === data);
    if (findToken) {
      //Remove
      setSelectNft(selectNft.filter((tk) => tk !== data));
    } else {
      //Add
      setSelectNft([...selectNft, data]);
    }
  };

  const handleSelectAll = () => {
    if (unStakelist) {
      setSelectNft([...unStakelist.map((snft: any) => Number(snft?.tokenId))]);
    }
  };

  const handleunSelectAll = () => {
    setSelectNft([]);
  };

  return (
    <Modal blurImg>
      <Card
        pseudoElement="default"
        className="!px-10 !pb-5"
        fullWidth={true}
        childClassName="!overflow-hidden !w-full !h-full"
      >
        <button
          onClick={() => !isloading && !isStakeLoading && onClose()}
          className="cursor-pointer absolute top-2 right-2 z-20"
        >
          <CancleIcon />
        </button>
        <div className="text-text-primary w-full h-full flex flex-col items-center justify-between">
          {/* //head */}
          <div className="">
            <Typography isIcon={false} className="text-2xl uppercase">
              Unstake NFT
            </Typography>
          </div>
          {/* body */}
          <div className="h-[80%] w-full overflow-hidden overflow-y-auto">
            {isloading ? (
              <Smallloader />
            ) : unStakelist && unStakelist.length === 0 ? (
              <Typography
                isIcon={false}
                font="bold"
                pclassName="w-full !h-full justify-center"
                className="text-2xl"
              >
                No NFT
              </Typography>
            ) : (
              <div className="grid md:grid-cols-2 justify-center items-center gap-3 gap-y-5 w-full min-h-full p-5">
                {unStakelist &&
                  unStakelist.map((stake, idx) => (
                    <SuperNovaCard
                      key={stake + idx}
                      isShadowActiveOnFll={false}
                      data={Number(stake?.tokenId)}
                      isSelectionButton={true}
                      // handleAddNft={handleAddNft}
                      handleUnStakeNft={handleunStakeNft}
                      selectNFT={selectNft}
                      isLoading={isStakeLoading}
                    />
                  ))}
              </div>
            )}
          </div>
          {/* footer */}
          <div className="w-full flex flex-col md:flex-row items-center gap-1 md:gap-5 mobile:gap-y-2">
            <div className="flex flex-row items-center gap-x-3 mobile:justify-between mobile:w-full">
              <Button
                size="medium"
                color="white"
                onClick={handleSelectAll}
                bgColor
                isBorder={true}
                isBorderLabel="Select All"
                disable={isloading || isStakeLoading}
                CLASSNAME={`text-text-primary group-hover:text-text-secondary px-3`}
                className="mobile:!w-full md:!w-40 mx-auto active:scale-95 whitespace-nowrap !h-7"
              />
              <Button
                size="medium"
                color="white"
                onClick={handleunSelectAll}
                bgColor
                isBorder={true}
                isBorderLabel="Clear"
                disable={isloading || isStakeLoading}
                CLASSNAME={`text-text-primary group-hover:text-text-secondary px-3`}
                className="mobile:!w-full md:!w-40 mx-auto active:scale-95 !h-7"
              />
            </div>
            <Button
              size="medium"
              color="white"
              bgColor
              isBorder={true}
              isBorderLabel="Unstake"
              disable={isloading || isStakeLoading}
              CLASSNAME={`text-text-primary group-hover:text-text-secondary px-3`}
              className="mobile:!w-full md:!w-40 mx-auto active:scale-95 !h-7"
              onClick={() =>
                onUnstakeNFT && onUnstakeNFT(user?.id || "", selectNft)
              }
            />
          </div>
        </div>
      </Card>
    </Modal>
  );
};
