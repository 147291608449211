// src/components/CountdownTimer.tsx
import React, { useEffect, useState } from "react";

type CountdownTimerProps = {
  positionCenter?: boolean;
};

const CountdownTimer: React.FC<CountdownTimerProps> = ({
  positionCenter = false,
}) => {
  const [timeLeft, setTimeLeft] = useState<number>(calculateTimeLeft());
  const [hidden, setHidden] = useState<boolean>(false);

  useEffect(() => {
    if (timeLeft <= 0) {
      setHidden(true);
      return;
    }

    const timerId = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timerId);
  }, [timeLeft]);

  function calculateTimeLeft(): number {
    const targetDate = new Date("2024-08-06T14:00:00Z");
    const now = new Date();
    const difference = targetDate.getTime() - now.getTime();
    return difference > 0 ? difference : 0;
  }

  function formatTimeLeft(ms: number): string {
    const totalSeconds = Math.floor(ms / 1000);
    const days = Math.floor(totalSeconds / (3600 * 24));
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  }

  if (hidden) return null;

  return (
    <div
      className={`flex px-3 md:my-2 md:px-7 bg-background-blacklight/90 md:bg-transparent ${
        positionCenter
          ? "items-center justify-center"
          : "justify-end items-end "
      }`}
    >
      <div className="text-sm md:text-xl font-bold drop-shadow-primary text-text-primary px-2 ">
        {formatTimeLeft(timeLeft)}
      </div>
    </div>
  );
};

export default CountdownTimer;
