export const Images = {
  SIGNUPBACKGROUND: require("./resources/signUpBg.png"),
  BLUR: require("./resources/noise.png"),
  HLINE: require("./resources/horizontalLine.png"),
  MARKGROUP: require("./resources/Mask group.png"),
  ADD: require("./resources/add.png"),
  SUB: require("./resources/minus.png"),
  ACCORDIAN_GARROW: require("./resources/accordianArrow.png"),
  ACCORDIAN_ARROW: require("./resources/accordianNormalArrow.png"),
  SOCIAL: require("./resources/social.png"),
  TWITTER: require("./resources/twitter.png"),
  INSTA: require("./resources/insta.png"),
  TELIGRAM: require("./resources/teligram.png"),
  DISCORD: require("./resources/discord.png"),
  RADDIT: require("./resources/raddit.png"),
  LINKEDIN: require("./resources/linkedin.png"),
  FACEBOOK: require("./resources/facebook.png"),
  COMBAT_BG: require("./resources/combatBg.png"),
  DIPLOMACY_BG: require("./resources/diplomacyBg.png"),
  CRAFTING_BG: require("./resources/craftingBg.png"),
  EXPLORATION_BG: require("./resources/explorationBg.png"),
  COMBAT: require("./resources/Combat.png"),
  DIPLOMACY: require("./resources/Diplomacy.png"),
  CRAFTING: require("./resources/Crafting.png"),
  EXPLORATION: require("./resources/Exploration.png"),
  DOWNLOAD: require("./resources/Downloded.png"),
  COPY: require("./resources/cpoy.png"),
  LINEDASHBOARD: require("./resources/line_Dashboard.png"),
  DASHBOARD_BG: require("./resources/Dashboard_Bg.png"),
  BG: require("./resources/bg.png"),
  MINTING: require("./resources/minting.png"),
  LEADERBOARD: require("./resources/Leaderboard.png"),
  LANDINGBG: require("./resources/LandingBg.png"),
  RIGHTROCK: require("./resources/rockRight.png"),
  LEFTROCK: require("./resources/rockLeft.png"),
  QUESTS: require("./resources/quests.png"),
  GETBLACKPASS: require("./resources/getBlackPass.png"),
  LINE_66: require("./resources/Line 66.png"),
  LINE: require("./resources/Line.png"),
  GROUP_ONE: require("./resources/Group one.png"),
  GROUP_TWO: require("./resources/Group two.png"),
  GROUP_THREE: require("./resources/Group three.png"),
  GROUP_Line: require("./resources/GroupIcon.png"),
  RVV: require("./resources/$RVV.png"),
  RVV_LOGO: require("./resources/Rvvlogo.png"),
  ASTRO_NOVO_REFERRAL: require("./resources/astra_nova.jpg"),
  LANDING_RVV: require("./resources/LandingRvv.png"),
  LANDING_LEFT_LINE: require("./resources/LandingLeftLine.png"),
  LANDING_R_LINE: require("./resources/LandingRLine.png"),
  BLACK_PASS_LAUNCH: require("./resources/blackpassLaunch.png"),
  VECTOR_V: require("./resources/v.png"),
  STEP1: require("./resources/Step1.png"),
  STEP2: require("./resources/Step2.png"),
  BladeStep3: require("./resources/BladeStep3.png"),
  BladeStep4: require("./resources/BladeStep4.png"),
  BladeStep5: require("./resources/BladeStep5.png"),
  HashpackStep3: require("./resources/HashpackStep3.png"),
  HashpackStep4: require("./resources/HashpacStep4.png"),
  HashpackStep5: require("./resources/HashpacStep5.png"),
  InfoLogo: require("./resources/info.png"),
  GOLDEN: require("./resources/golden.png"),
  CYAN: require("./resources/cyan.png"),
  TOKENTROVE: require("./resources/tokenTrovep.png"),
  SPHERE: require("./resources/sphere-4.png"),
  PARTNER1: require("./resources/partners/Resize2_0006_Nvidia_logo.png"),
  PARTNER2: require("./resources/partners/Resize2_0008_merit-circle.png"),
  PARTNER3: require("./resources/partners/Resize2_0015_hbar.png"),
  PARTNER4: require("./resources/partners/Resize2_0043_Polygon_DAO.png"),
  PARTNER5: require("./resources/partners/Resize2_0034_wemix.png"),
  PARTNER6: require("./resources/partners/Resize2_0016_hashgraph-association-(1).png"),
  PARTNER7: require("./resources/partners/Resize2_0007_MS-Startups-Founders-Hub.png"),
  PARTNER8: require("./resources/partners/Resize2_0017_Google_Cloud_logo.svg.png"),
  PARTNER9: require("./resources/partners/Resize2_0013_imx-logo.png"),
  PARTNER10: require("./resources/partners/Resize2_0023_Epic-Games.png"),
  PARTNER11: require("./resources/partners/Resize2_0035_Unreal_Engine.png"),
  PARTNER12: require("./resources/partners/Resize2_0032_aethir.png"),
  PARTNER13: require("./resources/partners/Resize2_0045_Hedera.png"),
  PARTNER14: require("./resources/partners/Resize2_0026_Crypto-dot-com.png"),
  PARTNER15: require("./resources/partners/Resize2_0042_portal-coin-logo.png"),
  PARTNER16: require("./resources/partners/Resize2_0033_MON-Protocol.png"),
  PARTNER17: require("./resources/partners/Resize2_0020_FUNToken.png"),
  PARTNER18: require("./resources/partners/Resize2_0041_Sensei-capital.png"),
  PARTNER19: require("./resources/partners/Resize2_0038_Tillo.png"),
  PARTNER20: require("./resources/partners/Resize2_0011_kairon.png"),
  PARTNER21: require("./resources/partners/Resize2_0027_BTS.png"),
  PARTNER22: require("./resources/partners/Resize2_0021_Equinox.png"),
  PARTNER23: require("./resources/partners/Resize2_0037_True-gaming.png"),
  PARTNER24: require("./resources/partners/Resize2_0031_analog.png"),
  PARTNER25: require("./resources/partners/Resize2_0024_Enjinstarter.png"),
  PARTNER26: require("./resources/partners/Resize2_0022_equi.png"),
  PARTNER27: require("./resources/partners/Resize2_0029_BGA-logo.png"),
  PARTNER28: require("./resources/partners/Resize2_0000_Velox.png"),
  PARTNER29: require("./resources/partners/Resize2_0012_isotopic.png"),
  PARTNER30: require("./resources/partners/Resize2_0001_Chainlink.png"),
  PARTNER31: require("./resources/partners/Resize2_0039_Spicy-capital.png"),
  PARTNER32: require("./resources/partners/Resize2_0004_Openoad.png"),
  PARTNER33: require("./resources/partners/Resize2_0014_Headstarter.png"),
  PARTNER34: require("./resources/partners/Resize2_0009_komunitas.png"),
  PARTNER35: require("./resources/partners/Resize2_0010_Kanga-BLack.png"),
  PARTNER36: require("./resources/partners/Resize2_0040_ShardsLogo_red.png"),
  PARTNER37: require("./resources/partners/Resize2_0028_Blade-Wallet.png"),
  PARTNER38: require("./resources/partners/Resize2_0005_OMA3-logo.png"),
  PARTNER39: require("./resources/partners/Resize2_0025_Elixir.png"),
  PARTNER40: require("./resources/partners/Resize2_0044_paal-ai-logo-cb568b71.png"),
  PARTNER41: require("./resources/partners/Resize2_0003_oxbull-logo.png"),
  PARTNER42: require("./resources/partners/Resize2_0002_Human.png"),
  PARTNER43: require("./resources/partners/Resize2_0036_Ultra.png"),
  PARTNER44: require("./resources/partners/Resize2_0030_Aza-Ventures.png"),
  PARTNER45: require("./resources/partners/Resize2_0048_Playmakers.png"),
  PARTNER46: require("./resources/partners/55.png"),
  PARTNER47: require("./resources/partners/52.png"),
  PARTNER48: require("./resources/partners/56.png"),
  PARTNER49: require("./resources/partners/Resize2_0050_ZKync3.png"),
  PARTNER50: require("./resources/partners/57.png"),
  PARTNER51: require("./resources/partners/58.png"),
  PARTNER52: require("./resources/partners/61.png"),
  PARTNER53: require("./resources/partners/62.png"),
  PARTNER54: require("./resources/partners/63.png"),
  PARTNER55: require("./resources/partners/64.png"),
  PARTNER56: require("./resources/partners/65.png"),
  PARTNER57: require("./resources/partners/66.png"),
  PARTNER58: require("./resources/partners/67.png"),
  PARTNER59: require("./resources/partners/68.png"),
  PARTNER60: require("./resources/partners/69.png"),
  PARTNER61: require("./resources/partners/Resize2_0018_gamefi.png"),
  PARTNER62: require("./resources/partners/Resize2_0019_GAM3S-logo_black-and-green.png"),
  PARTNER63: require("./resources/partners/Resize2_0046_Space3.png"),
  PARTNER64: require("./resources/partners/DEGAME.png"),
  PARTNER65: require("./resources/partners/Resize2_0047_Synapse network.png"),
  SPINNER_POINTER : require("./resources/spinner_pointer.png"),
  SPINNER : require("./resources/spinner.png"),
  LOADER : require("./resources/Loader.png"),
  CONGRATS_GIF : require("./gifs/Congrats.gif"),
  Shards : require("./resources/Shards.png"),
  Reward : require("./resources/reward.png"),
  Feature : require("./resources/feature.png"),
  Shardsrvv : require("./resources/shardssvv.png"),
  Identity : require("./resources/identity.png"),
  MOBPARTNER1: require("./resources/partners/Resize2_0006_Nvidia_logo.png"),
  MOBPARTNER2: require("./resources/partners/Resize2_0008_merit-circle.png"),
  MOBPARTNER3: require("./resources/partners/Resize2_0045_Hedera.png"),
  MOBPARTNER4: require("./resources/partners/Resize2_0043_Polygon_DAO.png"),
  MOBPARTNER5: require("./resources/partners/Resize2_0013_imx-logo.png"),
  MOBPARTNER6: require("./resources/partners/Resize2_0023_Epic-Games.png"),
  MOBPARTNER7: require("./resources/partners/Resize2_0035_Unreal_Engine.png"),
  MOBPARTNER8: require("./resources/partners/Resize2_0032_aethir.png"),
  MOBPARTNER9: require("./resources/partners/Resize2_0034_wemix.png"),
  MOBPARTNER10: require("./resources/partners/Resize2_0016_hashgraph-association-(1).png"),
  MOBPARTNER11: require("./resources/partners/Resize2_0007_MS-Startups-Founders-Hub.png"),
  MOBPARTNER12: require("./resources/partners/Resize2_0015_hbar.png"),
  MOBPARTNER13: require("./resources/partners/Resize2_0041_Sensei-capital.png"),
  MOBPARTNER14: require("./resources/partners/Resize2_0026_Crypto-dot-com.png"),
  MOBPARTNER15: require("./resources/partners/Resize2_0042_portal-coin-logo.png"),
  MOBPARTNER16: require("./resources/partners/Resize2_0033_MON-Protocol.png"),
  MOBPARTNER17: require("./resources/partners/Resize2_0017_Google_Cloud_logo.svg.png"),
  MOBPARTNER18: require("./resources/partners/Resize2_0022_equi.png"),
  MOBPARTNER19: require("./resources/partners/Resize2_0029_BGA-logo.png"),
  MOBPARTNER20: require("./resources/partners/Resize2_0000_Velox.png"),
  MOBPARTNER21: require("./resources/partners/Resize2_0012_isotopic.png"),
  MOBPARTNER22: require("./resources/partners/Resize2_0001_Chainlink.png"),
  MOBPARTNER23: require("./resources/partners/Resize2_0039_Spicy-capital.png"),
  MOBPARTNER24: require("./resources/partners/Resize2_0004_Openoad.png"),
  MOBPARTNER25: require("./resources/partners/Resize2_0014_Headstarter.png"),
  MOBPARTNER26: require("./resources/partners/Resize2_0009_komunitas.png"),
  MOBPARTNER27: require("./resources/partners/Resize2_0010_Kanga-BLack.png"),
  MOBPARTNER28: require("./resources/partners/Resize2_0040_ShardsLogo_red.png"),
  MOBPARTNER29: require("./resources/partners/Resize2_0027_BTS.png"),
  MOBPARTNER30: require("./resources/partners/Resize2_0021_Equinox.png"),
  MOBPARTNER31: require("./resources/partners/Resize2_0037_True-gaming.png"),
  MOBPARTNER32: require("./resources/partners/Resize2_0020_FUNToken.png"),
  MOBPARTNER33: require("./resources/partners/Resize2_0028_Blade-Wallet.png"),
  MOBPARTNER34: require("./resources/partners/Resize2_0005_OMA3-logo.png"),
  MOBPARTNER35: require("./resources/partners/Resize2_0025_Elixir.png"),
  MOBPARTNER36: require("./resources/partners/Resize2_0044_paal-ai-logo-cb568b71.png"),
  MOBPARTNER37: require("./resources/partners/Resize2_0031_analog.png"),
  MOBPARTNER38: require("./resources/partners/Resize2_0036_Ultra.png"),
  MOBPARTNER39: require("./resources/partners/Resize2_0038_Tillo.png"),
  MOBPARTNER40: require("./resources/partners/Resize2_0011_kairon.png"),
  MOBPARTNER41: require("./resources/partners/Resize2_0018_gamefi.png"),
  MOBPARTNER42: require("./resources/partners/Resize2_0019_GAM3S-logo_black-and-green.png"),
  MOBPARTNER43: require("./resources/partners/Resize2_0024_Enjinstarter.png"),
  MOBPARTNER44: require("./resources/partners/Resize2_0046_Space3.png"),
  MOBPARTNER45: require("./resources/partners/Resize2_0002_Human.png"),
  MOBPARTNER46: require("./resources/partners/Resize2_0003_oxbull-logo.png"),
  MOBPARTNER47: require("./resources/partners/Resize2_0030_Aza-Ventures.png"),
  MOBPARTNER48: require("./resources/partners/Resize2_0048_Playmakers.png"),
  MOBPARTNER49: require("./resources/partners/67.png"),
  MOBPARTNER50: require("./resources/partners/68.png"),
  MOBPARTNER51: require("./resources/partners/69.png"),
  MOBPARTNER52: require("./resources/partners/66.png"),
  MOBPARTNER53: require("./resources/partners/Resize2_0050_ZKync3.png"),
  MOBPARTNER54: require("./resources/partners/55.png"),
  MOBPARTNER55: require("./resources/partners/52.png"),
  MOBPARTNER56: require("./resources/partners/56.png"),
  MOBPARTNER57: require("./resources/partners/57.png"),
  MOBPARTNER58: require("./resources/partners/58.png"),
  MOBPARTNER59: require("./resources/partners/61.png"),
  MOBPARTNER60: require("./resources/partners/62.png"),
  MOBPARTNER61: require("./resources/partners/63.png"),
  MOBPARTNER62: require("./resources/partners/64.png"),
  MOBPARTNER63: require("./resources/partners/65.png"),
  MOBPARTNER64: require("./resources/partners/DEGAME.png"),
  MOBPARTNER65: require("./resources/partners/Resize2_0047_Synapse network.png"),
  OPBNB: require("./resources/AstraNova x opBNB.png"),
  BanWeb: require("./resources/banner/BanWeb.jpg"),
  BanMob: require("./resources/banner/BanMob.png"),
  BanMobLow: require("./resources/banner/BlurMob.jpg"),
  BanWebLow: require("./resources/banner/BlurWeb.jpg"),
  SUPER_BACKGROUND: require("./resources/superNova/Image 5.png"),
  EMPTY_CARD: require("./resources/superNova/cards/Empty.png"),
  EMPTY_CARD_LOW: require("./resources/superNova/cards/Emptylow.jpg"),
  FILL_CARD: require("./resources/superNova/cards/added.png"),
  TREASURE: require("./resources/superNova/wooden treasure box 1.png"),
  ADDNFT: require("./resources/superNova/Add.png"),
  SELECTNFT: require("./resources/superNova/Correct.png"),
  lOCK: require("./resources/superNova/Lock.png"),
  RVV_Shard: require("./resources/RvvShardIcon.png"),
  OVERLAY_LOW: require("./resources/overlaylow.jpg"),
  PROGRESS_BAR: require("./resources/superNova/ProgressBar/progress bar.png"),
  PROGRESS: require("./resources/superNova/123.png"),
  VIP: require("./resources/VIP.png"), 
  MOBBAN : require("./resources/banner/331x534.gif"),
};
