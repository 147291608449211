import { CancleIcon } from "assets";
import { Images } from "assets/Images";
import { Card } from "components/Atoms/Card/Card";
import { Typography } from "components/Atoms/Typography/Typography";
import { Modal } from "components/Molecules/Modal";
import { ShareCode } from "./ShareCode";

interface CongratulationsProps {
  closeCongrats: () => void;
}

export const Congratulations = ({ closeCongrats }: CongratulationsProps) => {
  return (
    <Modal blurImg>
      <Card pseudoElement="default" className="!px-0 !py-2 md:!h-72">
        <button
          onClick={() => closeCongrats()}
          className="cursor-pointer absolute top-2 right-2 z-30"
        >
          <CancleIcon />
        </button>
        <div className="flex flex-col justify-center items-center relative overflow-hidden">


          <Typography
            isIcon={false}
            variant="h2"
            font="bold"
            className="text-text-primary uppercase cursor-default mobile:text-xxxl text-center"
            >
            Congratulations! You have staked SuperNova key.
          </Typography>
          <div className="my-2 md:my-3 ">
            <img src={Images.LINE} alt="" />
          </div>
          <>
            <img
              src={Images.CONGRATS_GIF}
              alt="congrats"
              className="absolute h-full w-[50%] object-contain right-0 -rotate-12 "
            />
            <img
              src={Images.CONGRATS_GIF}
              alt="congrats"
              className="absolute h-full w-[50%] object-contain left-0 rotate-12"
            />
          </>
          <ShareCode BtnText="back to supernova" dataUrl="" onClose={closeCongrats}/>
        </div>
      </Card>
    </Modal>
  );
};
