import { CancleIcon } from "assets";
import { Images } from "assets/Images";
import { VIDEOS } from "assets/videos";
import { Card } from "components/Atoms/Card/Card";
import CountdownTimer from "components/Molecules/CountdownTimer";
import { Modal } from "components/Molecules/Modal";
import { BANNER_REDIRECT_LINK } from "constants/config";
import { Link } from "react-router-dom";

export const BanVideo = ({ onClose }: { onClose: () => void }) => {
  return (
    <Modal blurImg>
      <Card
        pseudoElement="default"
        className=" !p-0 !h-min relative overflow-hidden"
        childClassName="!p-0"
      >
        <button
          onClick={onClose}
          className="cursor-pointer absolute top-2 right-2 z-20"
        >
          <CancleIcon />
        </button>
        <div className="relative">
          <Link to={BANNER_REDIRECT_LINK} target="_blank">
          <video
            src={VIDEOS.UPDATE_WEB_ASTRANOVA}
            autoPlay
            muted
            loop
            playsInline
            className="hidden md:block"
            controls={false}
          />
          {/* <video
            src={VIDEOS.UPDATE_MOB_ASTRANOVA}
            autoPlay
            muted
            loop
            playsInline
            className="md:hidden"
            controls={false}
          /> */}
          <img src={Images.MOBBAN} alt="mob ban" className="md:hidden h-full w-full" />
          </Link>
        </div>
        <div>
          <CountdownTimer positionCenter={true} />
        </div>
      </Card>
    </Modal>
  );
};
